/* eslint-disable no-unused-vars */
import {
  FILE_UPLOAD_TYPE,
  OWNER_DIRECTOR_TYPES,
  AVATAR_COlORS,
  API_ERROR_CODES,
  API_CODE,
  COUNTRY_CODE,
  REGEX,
  FILE_SIZE,
  REQUIRED_LENGTH,
  SOURCE_APP
} from '../constants/enum';
import { RESOURCES_HUBSPOT_INTEGRATION, ENDPOINTS as ROUTE_ENDPOINTS } from '../service/services';
import { navigate } from 'gatsby';
import { getImageData } from 'gatsby-plugin-image';
import InvoiceApi from '../store/invoiceInterceptor';
import { ENDPOINTS } from '../service/invoice-service';
import axios from 'axios';
import {CLIENT_APPLICATION_TYPE } from '../constants/application-status'

export const handleEnter = (event) => {
  if (event.key.toLowerCase() === 'enter') {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }
  if (
    event.target.value.length === 0 &&
    event.key.toLowerCase() === 'backspace' &&
    (event.target.name === 'month' || event.target.name === 'year')
  ) {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);
    form.elements[index - 1].focus();
    event.preventDefault();
  }
};

export const companyAcceptedFileFormats = (selectedDocument) => {
  if (
    (selectedDocument[0].type === FILE_UPLOAD_TYPE.PDF ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.PNG ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.JPEG ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.MSWORD ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.OFFICE_DOCUMENT) &&
    selectedDocument[0].size <= FILE_SIZE.FOUR_MB_IN_BYTES
  )
    return true;
  else return false;
};

export const stakeHolderAcceptedFileFormats = (selectedDocument) => {
  if (
    (selectedDocument[0].type === FILE_UPLOAD_TYPE.PDF ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.PNG ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.JPEG) &&
    selectedDocument[0].size <= FILE_SIZE.FOUR_MB_IN_BYTES
  )
    return true;
  else return false;
};

export const stakeHolderOwnersCheck = (companyStakeHolder) => {
  if (
    companyStakeHolder.type === OWNER_DIRECTOR_TYPES.OWNER ||
    companyStakeHolder.type === OWNER_DIRECTOR_TYPES.OWNER_DIRECTOR ||
    companyStakeHolder.type === OWNER_DIRECTOR_TYPES.NOMINEE_OWNER_DIRECTOR ||
    companyStakeHolder.type === OWNER_DIRECTOR_TYPES.NOMINEE_OWNER
  )
    return true;
  else return false;
};
const colors = AVATAR_COlORS;
export function getAvatarBackgroundColor(text) {
  const charCodes = text
    ? text
        ?.split('')
        ?.map((char) => char.charCodeAt(0))
        ?.join('')
    : '-';
  return colors[parseInt(charCodes, 10) % colors.length];
}

export const MatchingOopsApiErrorStatus = (error) => {
  if (
    error?.response?.status !== API_ERROR_CODES.STATUS_422 &&
    error?.response?.status !== API_ERROR_CODES.STATUS_412 &&
    error?.response?.status !== API_ERROR_CODES.STATUS_401 &&
    error?.response?.status !== API_ERROR_CODES.STATUS_402 &&
    error?.response?.status !== API_ERROR_CODES.STATUS_403
  ) {
    return true;
  } else {
    return false;
  }
};

export const Matching401ApiErrorStatus = (error) => {
  if (error?.response?.status === API_ERROR_CODES.STATUS_401) {
    return true;
  } else {
    return false;
  }
};

export const sendHubspotRequest = (final_data, hubspot_integration, isCompanyRegistrationWaitingFlow) => {
  var xhr = new XMLHttpRequest();
  xhr.open('POST', hubspot_integration);
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(final_data);
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4 && xhr.status == API_CODE.STATUS_200) {
      if (!isCompanyRegistrationWaitingFlow) {
        navigate(`${process.env.MARKETING_SITE_URL}/thankyou`);
      }
    } else if (xhr.readyState == 4 && xhr.status == API_ERROR_CODES.STATUS_400) {
      console.log(xhr.responseText);
    } else if (xhr.readyState == 4 && xhr.status == API_ERROR_CODES.STATUS_403) {
      console.log(xhr.responseText);
    } else if (xhr.readyState == 4 && xhr.status == API_ERROR_CODES.STATUS_404) {
      console.log(xhr.responseText);
    }
  };
};

export const getCountryName = (countriesOptions, counrtyCode) => {
  let filterJurisdiction = countriesOptions?.find((countryOption) => {
    if (countryOption[COUNTRY_CODE.ALPHA_2] == counrtyCode) {
      return countryOption?.name;
    }
  });
  return filterJurisdiction;
};

export const getStateName = (statesOptions, statesCode) => {
 
  const state = statesOptions?.find((statesOption) => statesOption.code === statesCode);
  
  return state ? state.name : undefined;
};


export const sendHubspotRequestResources = (final_data, downloadURI) => {
  var xhr = new XMLHttpRequest();
  xhr.open('POST', RESOURCES_HUBSPOT_INTEGRATION.HUBSPOT_URL);
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(final_data);
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4 && xhr.status == API_CODE.STATUS_200) {
      downloadURI();
    } else if (xhr.readyState == 4 && xhr.status == API_ERROR_CODES.STATUS_400) {
      alert(xhr.responseText);
    } else if (xhr.readyState == 4 && xhr.status == API_ERROR_CODES.STATUS_403) {
      alert(xhr.responseText);
    } else if (xhr.readyState == 4 && xhr.status == API_ERROR_CODES.STATUS_404) {
      alert(xhr.responseText);
    }
  };
};

export const redirectUserToLogin = () => {
  location.replace(`${process.env.MARKETING_SITE_URL}/login`);
};

export const isUserLoggedIn = () => {
  let userLogin = sessionStorage.getItem('userLogin');
  let customerID = sessionStorage.getItem('customerID');
  let prospectCode = sessionStorage.getItem('prospectCode');
  if (!userLogin || !customerID || !prospectCode) {
    navigate(`${process.env.MARKETING_SITE_URL}/login`);
  } else {
    return true;
  }
};

export const isUserCodePresent = () => {
  let customerID = sessionStorage.getItem('customerID');
  if (!customerID  ) {
    navigate(`${process.env.MARKETING_SITE_URL}/login`);
  } else {
    return true;
  }
};

export const getClientCode = () => sessionStorage.getItem('clientCode') || '';

export const getKycCode = () => sessionStorage.getItem('kycCode') || '';

export const getSource = () => sessionStorage.getItem('source') || '';

export const getReviewType = () => {
  const reviewType = sessionStorage.getItem('reviewType') || '';
  return reviewType === 'PERIODIC_REVIEW' ? 'PERIODIC' : reviewType;
};

export const isProspectCodePresent = () => {
  let prospectCode = sessionStorage.getItem('prospectCode');
  if (!prospectCode) {
    return false;
  } else {
    return true;
  }
};

export const getChoppedDocumentName = (selectedDocs) => {
  let documentNameArray = selectedDocs[0]?.name?.split('.');
  let documentNameArrayLastElement = documentNameArray.pop();
  let modifiedDocumentName = documentNameArray.join('') + '.' + documentNameArrayLastElement;
  const [documentName, documentExt] = modifiedDocumentName.split('.');
  return [documentName.substring(0, REQUIRED_LENGTH.ALLOWED_DOCUMENT_LENGTH - 1 - documentExt.length), documentExt];
};

export const defaultValidationMessage = (fieldName) => {
  return `Please provide a valid ${fieldName}`;
};

export const handleReDirectToStore = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const expriyDateFormatter = (value) => {
  const expdate = value;
  const expDateFormatter =
    expdate.replace(REGEX.REPLACE_SLASH, '').substring(0, 2) +
    (expdate.length > 2 ? '/' : '') +
    expdate.replace(REGEX.REPLACE_SLASH, '').substring(2, 4);
  return expDateFormatter;
};

export const isBrowser = typeof window !== 'undefined';

export function getExampleImageData({ image, ...props }) {
  return getImageData({
    baseUrl: image?.url,
    sourceWidth: image?.width,
    sourceHeight: image?.height,
    urlBuilder,
    pluginName: 'gatsby-plugin-sharp',
    formats: ['auto', 'webp'],
    ...props
  });
}

export function redirectToPaymentAppAccountsTab(
  identifierCode,
  token,
  kycCode
) {
  const source = SOURCE_APP.ONBOARDING_APP;
  const type = CLIENT_APPLICATION_TYPE.BUSINESS_ACCOUNT;
  const url = `${process.env.PAYMENT_APP_URL}/authenticateUser?identifierCode=${identifierCode}&type=${type}&token=${token}&source=${source}${kycCode ? `&kycCode=${kycCode}` : ''}`;
  window.open(url, '_self');
}


export function urlBuilder({ baseUrl }) {
  return baseUrl;
}

export const sendDetailsToBackOffice = async (payload) => {
  try {
    return await InvoiceApi.post(ENDPOINTS?.BUSINESS_ACCOUNT, payload);
  } catch (error) {
    alert(error?.response?.message);
  }
};

export const updateBusinessAccountDetailsToBackOffice = async (payload) => {
  try {
    return await InvoiceApi.put(ENDPOINTS?.BUSINESS_ACCOUNT_UPDATE, payload);
  } catch (error) {
    alert(error?.response?.message);
  }
};

export const checkBusinessAccount = async (payload) => {
  try {
    return await InvoiceApi.post(ENDPOINTS?.CHECK_USER_ACCOUNT, payload);
  } catch (error) {
    alert(error?.response?.message);
  }
};

export function getCountryNameByCode(countryCode) {
  switch (countryCode.toUpperCase()) {
    case 'SG':
      return 'Singapore';
    case 'HK':
      return 'Hong Kong';
    case 'AR':
      return 'British Virgin';
    default:
      return 'Other Jurisdiction';
  }
}
export function isValidMonthAndYear(expirationDate) {
  const [month, year] = expirationDate.split('/').map(Number);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear() % 100; // Extract last two digits
  if (year < currentYear) {
    return false;
  }
  if (month < 1 || month > 12) {
    return false;
  }
  if (year === currentYear && month <= currentDate.getMonth() + 1) {
    return false;
  }
  return true;
}

export const getCookieValue = (cookieName) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(cookieName + '=')) {
      return cookie.substring(cookieName.length + 1);
    }
  }
  return null;
};

export async function fetchAppVersion() {
  try {
    let response = await axios.get('/app-version.html');
    let splittedAppVersion = response.data.toString().split('\n');
    let trimmedAppVersion = splittedAppVersion[0].trim();
    sessionStorage.setItem('app-version', trimmedAppVersion);
  } catch (error) {
    console.error(error);
  }
}

export const getFileExtension = (filename) => {
  const extension = filename?.split('.').pop();
  return extension;
};

export const shortenFileName = (fileName, maxLength) => {
  const extension = fileName?.split('.').pop();
  const baseName = fileName?.slice(0, -extension.length - 1);

  if (baseName?.length <= maxLength) {
    return fileName;
  }

  const startLength = Math.floor(maxLength / 2) - 2;
  const endLength = Math.ceil(maxLength / 2) - 2;

  const startPart = baseName.slice(0, startLength);
  const endPart = baseName.slice(-endLength);

  return `${startPart}...${endPart}.${extension}`;
};

export function getRequireParamsForSkipVerifyPage() {
  const prospectCode = sessionStorage.getItem('prospectCode');
  const customerID = sessionStorage.getItem('customerID');
  if (prospectCode && customerID) {
    return `prospectCode=${sessionStorage.getItem('prospectCode')}&customerID=${sessionStorage.getItem('customerID')}`;
  }

  return '';
}

export function onSkipVerifyIdentityURL(isYourselfAsNominee) {
  return `${
    isYourselfAsNominee ? ROUTE_ENDPOINTS.WHAT_IS_NEXT : ROUTE_ENDPOINTS.SKIP_IDENTIFY_VERIFICATION
  }?${getRequireParamsForSkipVerifyPage()}`;
}

export function checkIfExpired(expiryDate) {
  if (!expiryDate) return false; 

  const expiry = new Date(expiryDate);
  const today = new Date();
  today.setHours(0, 0, 0, 0); 

  return expiry < today;
}

