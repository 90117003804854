exports.components = {
  "component---src-pages-identity-verification-js": () => import("./../../../src/pages/identity-verification.js" /* webpackChunkName: "component---src-pages-identity-verification-js" */),
  "component---src-pages-identity-verification-start-verification-js": () => import("./../../../src/pages/identity-verification/start-verification.js" /* webpackChunkName: "component---src-pages-identity-verification-start-verification-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kyc-account-review-setup-js": () => import("./../../../src/pages/kyc/account-review-setup.js" /* webpackChunkName: "component---src-pages-kyc-account-review-setup-js" */),
  "component---src-pages-kyc-account-setup-business-details-js": () => import("./../../../src/pages/kyc/account-setup/business-details.js" /* webpackChunkName: "component---src-pages-kyc-account-setup-business-details-js" */),
  "component---src-pages-kyc-account-setup-company-set-up-success-js": () => import("./../../../src/pages/kyc/account-setup/company-set-up-success.js" /* webpackChunkName: "component---src-pages-kyc-account-setup-company-set-up-success-js" */),
  "component---src-pages-kyc-account-setup-company-structure-js": () => import("./../../../src/pages/kyc/account-setup/company-structure.js" /* webpackChunkName: "component---src-pages-kyc-account-setup-company-structure-js" */),
  "component---src-pages-kyc-account-setup-confirm-phone-number-js": () => import("./../../../src/pages/kyc/account-setup/confirm-phone-number.js" /* webpackChunkName: "component---src-pages-kyc-account-setup-confirm-phone-number-js" */),
  "component---src-pages-kyc-account-setup-documents-js": () => import("./../../../src/pages/kyc/account-setup/documents.js" /* webpackChunkName: "component---src-pages-kyc-account-setup-documents-js" */),
  "component---src-pages-kyc-account-setup-js": () => import("./../../../src/pages/kyc/account-setup.js" /* webpackChunkName: "component---src-pages-kyc-account-setup-js" */),
  "component---src-pages-kyc-account-setup-mobile-number-verified-successfully-js": () => import("./../../../src/pages/kyc/account-setup/mobile-number-verified-successfully.js" /* webpackChunkName: "component---src-pages-kyc-account-setup-mobile-number-verified-successfully-js" */),
  "component---src-pages-kyc-account-setup-review-and-submit-js": () => import("./../../../src/pages/kyc/account-setup/review-and-submit.js" /* webpackChunkName: "component---src-pages-kyc-account-setup-review-and-submit-js" */),
  "component---src-pages-kyc-account-setup-submitted-successfully-js": () => import("./../../../src/pages/kyc/account-setup/submitted-successfully.js" /* webpackChunkName: "component---src-pages-kyc-account-setup-submitted-successfully-js" */),
  "component---src-pages-kyc-account-setup-verify-mobile-number-js": () => import("./../../../src/pages/kyc/account-setup/verify-mobile-number.js" /* webpackChunkName: "component---src-pages-kyc-account-setup-verify-mobile-number-js" */),
  "component---src-pages-kyc-confirm-identify-verification-js": () => import("./../../../src/pages/kyc/confirm-identify-verification.js" /* webpackChunkName: "component---src-pages-kyc-confirm-identify-verification-js" */),
  "component---src-pages-kyc-failure-js": () => import("./../../../src/pages/kyc/failure.js" /* webpackChunkName: "component---src-pages-kyc-failure-js" */),
  "component---src-pages-kyc-getstarted-js": () => import("./../../../src/pages/kyc/getstarted.js" /* webpackChunkName: "component---src-pages-kyc-getstarted-js" */),
  "component---src-pages-kyc-js": () => import("./../../../src/pages/kyc.js" /* webpackChunkName: "component---src-pages-kyc-js" */),
  "component---src-pages-kyc-questionnaire-js": () => import("./../../../src/pages/kyc/questionnaire.js" /* webpackChunkName: "component---src-pages-kyc-questionnaire-js" */),
  "component---src-pages-kyc-select-manager-js": () => import("./../../../src/pages/kyc/selectManager.js" /* webpackChunkName: "component---src-pages-kyc-select-manager-js" */),
  "component---src-pages-kyc-signup-start-js": () => import("./../../../src/pages/kyc/signupStart.js" /* webpackChunkName: "component---src-pages-kyc-signup-start-js" */),
  "component---src-pages-kyc-skip-identify-verification-js": () => import("./../../../src/pages/kyc/skip-identify-verification.js" /* webpackChunkName: "component---src-pages-kyc-skip-identify-verification-js" */),
  "component---src-pages-kyc-start-verification-js": () => import("./../../../src/pages/kyc/start-verification.js" /* webpackChunkName: "component---src-pages-kyc-start-verification-js" */),
  "component---src-pages-kyc-what-is-next-js": () => import("./../../../src/pages/kyc/what-is-next.js" /* webpackChunkName: "component---src-pages-kyc-what-is-next-js" */),
  "component---src-pages-kyc-wrappers-main-wrapper-js": () => import("./../../../src/pages/kyc/wrappers/MainWrapper.js" /* webpackChunkName: "component---src-pages-kyc-wrappers-main-wrapper-js" */),
  "component---src-pages-kyc-wrappers-vertical-linear-stepper-js": () => import("./../../../src/pages/kyc/wrappers/VerticalLinearStepper.js" /* webpackChunkName: "component---src-pages-kyc-wrappers-vertical-linear-stepper-js" */),
  "component---src-pages-onboarding-account-setup-business-details-js": () => import("./../../../src/pages/onboarding/account-setup/business-details.js" /* webpackChunkName: "component---src-pages-onboarding-account-setup-business-details-js" */),
  "component---src-pages-onboarding-account-setup-company-information-js": () => import("./../../../src/pages/onboarding/account-setup/company-information.js" /* webpackChunkName: "component---src-pages-onboarding-account-setup-company-information-js" */),
  "component---src-pages-onboarding-account-setup-company-set-up-success-js": () => import("./../../../src/pages/onboarding/account-setup/company-set-up-success.js" /* webpackChunkName: "component---src-pages-onboarding-account-setup-company-set-up-success-js" */),
  "component---src-pages-onboarding-account-setup-company-structure-js": () => import("./../../../src/pages/onboarding/account-setup/company-structure.js" /* webpackChunkName: "component---src-pages-onboarding-account-setup-company-structure-js" */),
  "component---src-pages-onboarding-account-setup-confirm-phone-number-js": () => import("./../../../src/pages/onboarding/account-setup/confirm-phone-number.js" /* webpackChunkName: "component---src-pages-onboarding-account-setup-confirm-phone-number-js" */),
  "component---src-pages-onboarding-account-setup-documents-js": () => import("./../../../src/pages/onboarding/account-setup/documents.js" /* webpackChunkName: "component---src-pages-onboarding-account-setup-documents-js" */),
  "component---src-pages-onboarding-account-setup-js": () => import("./../../../src/pages/onboarding/account-setup.js" /* webpackChunkName: "component---src-pages-onboarding-account-setup-js" */),
  "component---src-pages-onboarding-account-setup-mobile-number-verified-successfully-js": () => import("./../../../src/pages/onboarding/account-setup/mobile-number-verified-successfully.js" /* webpackChunkName: "component---src-pages-onboarding-account-setup-mobile-number-verified-successfully-js" */),
  "component---src-pages-onboarding-account-setup-review-and-submit-js": () => import("./../../../src/pages/onboarding/account-setup/review-and-submit.js" /* webpackChunkName: "component---src-pages-onboarding-account-setup-review-and-submit-js" */),
  "component---src-pages-onboarding-account-setup-submitted-successfully-js": () => import("./../../../src/pages/onboarding/account-setup/submitted-successfully.js" /* webpackChunkName: "component---src-pages-onboarding-account-setup-submitted-successfully-js" */),
  "component---src-pages-onboarding-account-setup-verify-mobile-number-js": () => import("./../../../src/pages/onboarding/account-setup/verify-mobile-number.js" /* webpackChunkName: "component---src-pages-onboarding-account-setup-verify-mobile-number-js" */),
  "component---src-pages-onboarding-confirm-identify-verification-js": () => import("./../../../src/pages/onboarding/confirm-identify-verification.js" /* webpackChunkName: "component---src-pages-onboarding-confirm-identify-verification-js" */),
  "component---src-pages-onboarding-failure-js": () => import("./../../../src/pages/onboarding/failure.js" /* webpackChunkName: "component---src-pages-onboarding-failure-js" */),
  "component---src-pages-onboarding-getstarted-js": () => import("./../../../src/pages/onboarding/getstarted.js" /* webpackChunkName: "component---src-pages-onboarding-getstarted-js" */),
  "component---src-pages-onboarding-js": () => import("./../../../src/pages/onboarding.js" /* webpackChunkName: "component---src-pages-onboarding-js" */),
  "component---src-pages-onboarding-questionnaire-js": () => import("./../../../src/pages/onboarding/questionnaire.js" /* webpackChunkName: "component---src-pages-onboarding-questionnaire-js" */),
  "component---src-pages-onboarding-select-manager-js": () => import("./../../../src/pages/onboarding/selectManager.js" /* webpackChunkName: "component---src-pages-onboarding-select-manager-js" */),
  "component---src-pages-onboarding-signup-start-js": () => import("./../../../src/pages/onboarding/signupStart.js" /* webpackChunkName: "component---src-pages-onboarding-signup-start-js" */),
  "component---src-pages-onboarding-skip-identify-verification-js": () => import("./../../../src/pages/onboarding/skip-identify-verification.js" /* webpackChunkName: "component---src-pages-onboarding-skip-identify-verification-js" */),
  "component---src-pages-onboarding-start-verification-js": () => import("./../../../src/pages/onboarding/start-verification.js" /* webpackChunkName: "component---src-pages-onboarding-start-verification-js" */),
  "component---src-pages-onboarding-what-is-next-js": () => import("./../../../src/pages/onboarding/what-is-next.js" /* webpackChunkName: "component---src-pages-onboarding-what-is-next-js" */),
  "component---src-pages-onboarding-wrappers-main-wrapper-js": () => import("./../../../src/pages/onboarding/wrappers/MainWrapper.js" /* webpackChunkName: "component---src-pages-onboarding-wrappers-main-wrapper-js" */),
  "component---src-pages-onboarding-wrappers-vertical-linear-stepper-js": () => import("./../../../src/pages/onboarding/wrappers/VerticalLinearStepper.js" /* webpackChunkName: "component---src-pages-onboarding-wrappers-vertical-linear-stepper-js" */)
}

