export const JURISDICTION_COUNTRY_REQUEST_SUCCESS = 'JURISDICTION_COUNTRY_REQUEST_SUCCESS';

export const SENT_OTP_REQUEST_START = 'SENT_OTP_REQUEST_START';
export const SENT_OTP_REQUEST_FAIL = 'SENT_OTP_REQUEST_FAIL';
export const SENT_OTP_REQUEST_SUCCESS = 'SENT_OTP_REQUEST_SUCCESS';

export const VERIFY_OTP_REQUEST_START = 'VERIFY_OTP_REQUEST_START';
export const VERIFY_OTP_REQUEST_FAIL = 'VERIFY_OTP_REQUEST_FAIL';
export const VERIFY_OTP_REQUEST_SUCCESS = 'VERIFY_OTP_REQUEST_SUCCESS';

export const CUSTOMER_UID_REQUEST_SUCCESS = 'CUSTOMER_UID_REQUEST_SUCCESS';

export const VALIDATE_TOKEN_REQUEST_START = 'VALIDATE_TOKEN_REQUEST_START';
export const VALIDATE_TOKEN_REQUEST_FAIL = 'VALIDATE_TOKEN_REQUEST_FAIL';
export const VALIDATE_TOKEN_REQUEST_SUCCESS = 'VALIDATE_TOKEN_REQUEST_SUCCESS';

export const SET_PASSWORD_REQUEST_START = 'SET_PASSWORD_REQUEST_START';
export const SET_PASSWORD_REQUEST_FAIL = 'SET_PASSWORD_REQUEST_FAIL';
export const SET_PASSWORD_REQUEST_SUCCESS = 'SET_PASSWORD_REQUEST_SUCCESS';

export const USER_LOGIN_REQUEST_START = 'USER_LOGIN_REQUEST_START';
export const USER_LOGIN_REQUEST_FAIL = 'USER_LOGIN_REQUEST_FAIL';
export const USER_LOGIN_REQUEST_SUCCESS = 'USER_LOGIN_REQUEST_SUCCESS';

export const GET_TOKEN_REQUEST_START = 'GET_TOKEN_REQUEST_START';
export const GET_TOKEN_REQUEST_FAIL = 'GET_TOKEN_REQUEST_FAIL';
export const GET_TOKEN_REQUEST_SUCCESS = 'GET_TOKEN_REQUEST_SUCCESS';

export const USER_ONBOARDING_REQUEST_START = 'USER_ONBOARDING_REQUEST_START';
export const USER_ONBOARDING_REQUEST_FAIL = 'USER_ONBOARDING_REQUEST_FAIL';
export const USER_ONBOARDING_REQUEST_SUCCESS = 'USER_ONBOARDING_REQUEST_SUCCESS';

export const NATIONALITIES_LIST_REQUEST_START = 'NATIONALITIES_LIST_REQUEST_START';
export const NATIONALITIES_LIST_REQUEST_FAIL = 'NATIONALITIES_LIST_REQUEST_FAIL';
export const NATIONALITIES_LIST_REQUEST_SUCCESS = 'NATIONALITIES_LIST_REQUEST_SUCCESS';

export const OTHER_JURISDICTION_COUNTRIES_LIST_REQUEST_START = 'OTHER_JURISDICTION_COUNTRIES_LIST_REQUEST_START';
export const OTHER_JURISDICTION_COUNTRIES_LIST_REQUEST_FAIL = 'OTHER_JURISDICTION_COUNTRIES_LIST_REQUEST_FAIL';
export const OTHER_JURISDICTION_COUNTRIES_LIST_REQUEST_SUCCESS = 'OTHER_JURISDICTION_COUNTRIES_LIST_REQUEST_SUCCESS';

export const RESET_REDUX_DATA = 'RESET_REDUX_DATA';

export const NEW_COMPANY_REGISTRATION = 'NEW_COMPANY_REGISTRATION';
export const COMPLETED_COMPANY_REGISTRATION = 'COMPLETED_COMPANY_REGISTRATION';
export const ADD_STAKEHOLDER_DATA = 'ADD_STAKEHOLDER_DATA';
export const STAKEHOLDER_EDIT_DETAILS = 'STAKEHOLDER_EDIT_DETAILS';
export const STAKEHOLDER_DETAILS_REMOVE = 'STAKEHOLDER_DETAILS_REMOVE';
export const ADD_ALL_STAKEHOLDER = 'ADD_ALL_STAKEHOLDER';

export const COMPANY_CREATE_REQUEST_START = 'COMPANY_CREATE_REQUEST_START';
export const COMPANY_CREATE_REQUEST_SUCCESS = 'COMPANY_CREATE_REQUEST_SUCCESS';
export const COMPANY_CREATE_REQUEST_FAILURE = 'COMPANY_CREATE_REQUEST_FAILURE';

export const COMPANY_UPDATE_REQUEST_START = 'COMPANY_UPDATE_REQUEST_START';
export const COMPANY_UPDATE_REQUEST_SUCCESS = 'COMPANY_UPDATE_REQUEST_SUCCESS';
export const COMPANY_UPDATE_REQUEST_FAILURE = 'COMPANY_UPDATE_REQUEST_FAILURE';

export const COMPANY_DETAILS_REQUEST_START = 'COMPANY_DETAILS_REQUEST_START';
export const COMPANY_DETAILS_REQUEST_SUCCESS = 'COMPANY_DETAILS_REQUEST_SUCCESS';
export const COMPANY_DETAILS_REQUEST_FAILURE = 'COMPANY_DETAILS_REQUEST_FAILURE';

export const UPLOAD_FILE_REQUEST_START = 'UPLOAD_FILE_REQUEST_START';
export const UPLOAD_FILE_REQUEST_SUCCESS = 'UPLOAD_FILE_REQUEST_SUCCESS';
export const UPLOAD_FILE_REQUEST_FAILURE = 'UPLOAD_FILE_REQUEST_FAILURE';

export const PAYMENT_REQUEST_START = 'PAYMENT_REQUEST_START';
export const PAYMENT_REQUEST_SUCCESS = 'PAYMENT_REQUEST_SUCCESS';
export const PAYMENT_REQUEST_FAILURE = 'PAYMENT_REQUEST_FAILURE';

export const DELETE_STAKHOLDER_REQUEST_START = 'DELETE_STAKHOLDER_REQUEST_START';
export const DELETE_STAKHOLDER_SUCCESS = 'DELETE_STAKHOLDER_SUCCESS';
export const DELETE_STAKHOLDER_FAILURE = 'DELETE_STAKHOLDER_FAILURE';

export const FETCH_CLIENTS_START = 'FETCH_CLIENTS_START';
export const FETCH_CLIENTS_FAIL = 'FETCH_CLIENTS_FAIL';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';

export const FETCH_PAYMENT_CARD_APPLICATIONS_START = 'FETCH_PAYMENT_CARD_APPLICATIONS_START';
export const FETCH_PAYMENT_CARD_APPLICATIONS_FAIL = 'FETCH_PAYMENT_CARD_APPLICATIONS_FAIL';
export const FETCH_PAYMENT_CARD_APPLICATIONS_SUCCESS = 'FETCH_PAYMENT_CARD_APPLICATIONS_SUCCESS';

export const FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_COMPLETED_START =
  'FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_COMPLETED_START';
export const FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_COMPLETED_FAIL =
  'FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_COMPLETED_FAIL';
export const FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_COMPLETED_SUCCESS =
  'FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_COMPLETED_SUCCESS';

export const FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_INCOMPLETE_START =
  'FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_INCOMPLETE_START';
export const FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_INCOMPLETE_FAIL =
  'FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_INCOMPLETE_FAIL';
export const FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_INCOMPLETE_SUCCESS =
  'FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_INCOMPLETE_SUCCESS';

export const FETCH_DEFAULT_TOKEN_START = 'FETCH_DEFAULT_TOKEN_START';
export const FETCH_DEFAULT_TOKEN_FAIL = 'FETCH_DEFAULT_TOKEN_FAIL';
export const FETCH_DEFAULT_TOKEN_SUCCESS = 'FETCH_DEFAULT_TOKEN_SUCCESS';

export const GENERATE_MFA_OTP_START = 'GENERATE_MFA_OTP_START';
export const GENERATE_MFA_OTP_FAIL = 'GENERATE_MFA_OTP_FAIL';
export const GENERATE_MFA_OTP_SUCCESS = 'GENERATE_MFA_OTP_SUCCESS';

export const VERIFY_MFA_OTP_START = 'VERIFY_MFA_OTP_START';
export const VERIFY_MFA_OTP_FAIL = 'VERIFY_MFA_OTP_FAIL';
export const VERIFY_MFA_OTP_SUCCESS = 'VERIFY_MFA_OTP_SUCCESS';

export const VERIFY_MFA_NOTIFICATION_STATUS_START = 'VERIFY_MFA_NOTIFICATION_STATUS_START';
export const VERIFY_MFA_NOTIFICATION_STATUS_FAIL = 'VERIFY_MFA_NOTIFICATION_STATUS_FAIL';
export const VERIFY_MFA_NOTIFICATION_STATUS_SUCCESS = 'VERIFY_MFA_NOTIFICATION_STATUS_SUCCESS';

export const GENERATE_MFA_NOTIFICATION_START = 'GENERATE_MFA_NOTIFICATION_START';
export const GENERATE_MFA_NOTIFICATION_FAIL = 'GENERATE_MFA_NOTIFICATION_FAIL';
export const GENERATE_MFA_NOTIFICATION_SUCCESS = 'GENERATE_MFA_NOTIFICATION_SUCCESS';

export const VALIDATE_SETUP_PASSWORD_TOKEN_START = 'VALIDATE_SETUP_PASSWORD_TOKEN_START';
export const VALIDATE_SETUP_PASSWORD_TOKEN_FAIL = 'VALIDATE_SETUP_PASSWORD_TOKEN_FAIL';
export const VALIDATE_SETUP_PASSWORD_TOKEN_SUCCESS = 'VALIDATE_SETUP_PASSWORD_TOKEN_SUCCESS';

export const UPDATE_USER_PASSWORD_START = 'UPDATE_USER_PASSWORD_START';
export const UPDATE_USER_PASSWORD_FAIL = 'UPDATE_USER_PASSWORD_FAIL';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';

export const RESEND_PASSWORD_SETUP_LINK_START = 'RESEND_PASSWORD_SETUP_LINK_START';
export const RESEND_PASSWORD_SETUP_LINK_FAIL = 'RESEND_PASSWORD_SETUP_LINK_FAIL';
export const RESEND_PASSWORD_SETUP_LINK_SUCCESS = 'RESEND_PASSWORD_SETUP_LINK_SUCCESS';

export const SSO_LOGIN_REQUEST_START = 'SSO_LOGIN_REQUEST_START';
export const SSO_LOGIN_REQUEST_FAIL = 'SSO_LOGIN_REQUEST_FAIL';
export const SSO_LOGIN_REQUEST_SUCCESS = 'SSO_LOGIN_REQUEST_SUCCESS';

export const GET_TOKEN_FROM_SSO_START = 'GET_TOKEN_FROM_SSO_START';
export const GET_TOKEN_FROM_SSO_FAIL = 'GET_TOKEN_FROM_SSO_FAIL';
export const GET_TOKEN_FROM_SSO_SUCCESS = 'GET_TOKEN_FROM_SSO_SUCCESS';

export const GET_SSO_FLAG_START = 'GET_SSO_FLAG_START';
export const GET_SSO_FLAG_FAIL = 'GET_SSO_FLAG_FAIL';
export const GET_SSO_FLAG_SUCCESS = 'GET_SSO_FLAG_SUCCESS';

export const LOGOUT_SSO_USER_START = 'LOGOUT_SSO_USER_START';
export const LOGOUT_SSO_USER_FAIL = 'LOGOUT_SSO_USER_FAIL';
export const LOGOUT_SSO_USER_SUCCESS = 'LOGOUT_SSO_USER_SUCCESS';
